@import "~styles/colors";

.property-popup {
  display: inline-block;
  overflow: hidden;
  padding: 0;
  border: none;
  z-index: 1;
  position: relative;
  width: 230px;
  overflow-y: scroll;
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
  }
  &:not(.invalid-property) {
    height: 330px;

    &.height-130 {
      height: 130px;
    }
  }
  &.all-map{
   height: 200px;
  }
  &.invalid-property {
    color: $color2;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    justify-content: space-between;
    .close-button {
      color: $color2;
      border: 1px solid $color2;
      border-radius: 50%;
      padding: 5px;
      font-size: 7px;
    }
  }
  &-loader {
    padding: 80px 50px;
  }
  &-header {
    background-color: $color2;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    span {
      flex: 1;
      display: block;
      color: #ffffff;
      padding: 0 10px;
    }
    .add-button {
      background-color: #ffffff;
      color: #666666;
      border-radius: 50%;
      padding: 5px 7px;
    }
    .close-button {
      color: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 50%;
      padding: 5px;
      font-size: 7px;
    }
  }
  &-action {
    &-item {
      padding: 13px 20px;
      display: flex;
      align-items: center;
      color: #666666;
      width: 100%;
      background: none;
      font-size: 13px;
      border: none;
      .form-group {
        width: 100%;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #e2e2e2;
      }
      i {
        padding-right: 20px;
      }
    }
  }
  .button-container {
    text-align: center;
    padding: 15px 0;
  }
}

.gm-style-iw.gm-style-iw-c {
  padding: 0 !important;
  .gm-style-iw-d {
    overflow: hidden !important;
  }
  & > button {
    display: none !important;
  }

  &.height-130 {
    height: 130px;
  }
}
