.color-picker {
  position: relative;
  margin-bottom: 15px;
  &-bg {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: #00000015;
    left: 0;
    top: 0;
    z-index: 100;
    cursor: pointer;
  }
  .sketch-picker {
    z-index: 200;
    position: absolute;
  }
  &-inner {
    display: flex;
  }
  &-thumb {
    height: 37px;
    display: block;
    width: 70px;
    margin-right: 15px;
    border-radius: 5px;
    border: 0.5px solid;
    cursor: pointer;
  }
  .form-input {
    margin-bottom: 0;
  }
  .form-errors {
    list-style: none;
    text-align: left;
    font-size: 12px;
    padding: 10px 0 0;
    color: #cc6363;
  }
}
