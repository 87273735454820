@import "~styles/colors";

.add-tags-and-layers {
  .add-tags-and-layers-dropdown {
    &--active {
      margin-bottom: 20px;
      background: white;
      box-shadow: 0 0 6px 0 #3333;
    }
  }
  .tag-container {
    display: flex;
    margin-bottom: 20px;
    svg {
      margin-right: 20px;
    }
    .hashtag-autocomplete {
      flex: 1;
    }
  }
  &-dropdown {
    color: #00aeef;
  }
  .button-container{
    margin-top: 15px;
  }
}
