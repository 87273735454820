@import "~styles/colors";

.campaign-template-details {
  .access-level {
    display: flex;
    margin: 20px 0 0;
    label {
      margin-right: 15px;
    }
    .switch-container {
      margin-right: 15px;
    }
  }
  .button-block {
    margin-bottom: 10px;
  }
  &-header {
    padding-left: 15px;
    &-on-edit {
      width: 100%;
    }
    &-left {
      flex: 1;
      span {
        display: block;
      }
    }
  }
  &-title {
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: center;
    button {
      color: $color2;
    }
  }
  &-created-by {
    font-size: 12px;
    color: $color2;
    display: block;
  }
  &-updated-by {
    font-size: 10px;
  }
  &-description {
    font-size: 12px;
    display: flex;
    align-items: center;
    margin: 5px 0 10px;
    button {
      color: $color2;
    }
  }
}
