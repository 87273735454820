$size: 20px;
.icon-button {
  .loading {
    padding-top: 2px;
    .loader-spin {
      display: inline-block;
      width: $size;
      height: $size;
      &:after {
        content: " ";
        display: block;
        width: $size;
        height: $size;
        margin: 1px;
        border-radius: 50%;
        border: 2px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: lds-dual-ring 1.2s linear infinite;
      }
    }
  }
}
