.textarea{
  position: relative;
  margin-bottom: 15px;
  textarea {
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #F9F9F9;
    font-size: 13px;
    line-height: 1;
    color: #707070;
  }
  &.has-errors {
    textarea {
      border-color: #cc6363 !important;
      box-shadow: 0 0 2px 1px #ffa3a3;
    }
  }
  .form-errors {
    list-style: none;
    text-align: left;
    font-size: 12px;
    margin-top: 5px;
    color: #cc6363;
  }
}
