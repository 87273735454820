@import "~styles/colors";

.people-tab {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &-content {
    padding: 10px 5px;
    height: 100%;
    .people-list {
      &-item {
        box-shadow: 0 0 5px #00000016;
        padding: 5px 20px;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &-type {
          list-style: none;
          padding: 0;
          li {
            display: inline-block;
            padding: 3px 8px;
            font-size: 12px;
            color: #afafaf;
            position: relative;
            &:first-child {
              padding-left: 0;
            }
            &.pull-right {
              float: right;
              button {
                background-color: $color1;
                outline: none;
                border: none;
                color: #ffffff;
                padding: 7px;
                border-radius: 50%;
                line-height: 10px;
                box-shadow: 0 0 5px #00000016;
              }
            }
            &.active {
              color: $color2;
              &:after {
                content: "";
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $color1;
                left: calc(50% - 3px);
                bottom: -5px;
              }
            }
          }
        }
      }
    }
    &-details {
      list-style: none;
      padding: 0;
      &-icon {
        margin-right: 10px;
        color: $color2;
      }
      li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 11px;
        img {
          max-width: 12px;
          margin-right: 10px;
        }
        .form-input {
          margin-bottom: 0;
          flex: 1;
        }
        .form-group {
          flex: 1;
        }
      }
    }
  }
}
