.sun-editor {
  border-color: transparent;
}

.sun-editor .se-container {
  display: flex;
}

.sun-editor .se-wrapper {
  overflow-y: scroll;
}
