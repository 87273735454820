@import 'styles/colors.scss';

.confirm-alert {
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 10px);

  &__content {
    z-index: 300;
    background-color: $white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 1em;
    border-radius: 5px;
    width: auto;
    max-width: 500px;
  }

  &__overlay {
    z-index: 500;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &__actions {
    padding-top: 1em;
    > :not(:last-child) {
      margin-bottom: 10px;
    }
    @media (min-width: 426px) {
      display: flex;
      justify-content: flex-end;
      > :not(:last-child) {
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
  }
}
