.campaign-template-list-container {
  &-header {
    h1 {
      font-weight: 500;
      font-size: 25px;
    }
    &-search {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      .search-container {
        flex: 1;
        margin-right: 20px;
        max-width: 400px;
      }
    }
  }
  &-content {
    .tabs.primary > .tab-header .tab-item {
      font-size: 14px;
    }
  }
}
