@import "~styles/colors";

.property-modal {
  height: 100%;
  &-content {
    &-header {
      display: flex;
      align-items: center;
      .form-group {
        margin-left: 20px;
      }
    }
    .actions {
      margin: 20px 0;
    }
    .actions-disabled {
      pointer-events: none;
      opacity: 0.4;
    }
    @media (max-width: 768px) {
      max-height: calc(100vh - 100px);
      height: 100%;
      overflow: scroll;
    }
  }
}

.property-over-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
