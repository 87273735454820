body {
  margin: 0;
}

.box {
  display: block;
  text-decoration: none;
  font-size: 13px;
  padding: 10px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #70707014;
  box-shadow: 0 0 10px #00000016;
  margin-bottom: 10px;
}
