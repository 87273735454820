.campaign-user-list-item {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  &-left {
    display: flex;
    align-items: center;
  }
  &-right {
    display: flex;
    align-items: center;
    .user-color {
      background: red;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 5px;
    }
    button {
      color: #e95757;
      font-size: 17px;
      padding: 0 2px;
      i {
        visibility: hidden;
      }
    }
  }
  img {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin-right: 10px;
  }
}

.campaign-user-list-item:hover {
  i {
    visibility: visible;
  }
}
