.map-layer{
  overflow: hidden;
  box-shadow: 0 0 5px #00000026;
  margin-bottom: 20px;
  border-radius: 5px;
  &-row{
    @media (min-width: 769px) {
      display: flex;
      align-items: center;
    } 
  }
  &-action{
    padding: 0 10px;
  }
  &-meta{
    display: none;
    padding: 15px;
  }
  &.expanded{
    .map-layer-details{
      display: none;
    }
    .map-layer-meta{
      display: block;
      @media (min-width: 769px) {
        display: flex;
      }
    }
  }
  &-preview {
    flex: 1;
    display: flex;
    .primary-color {
      height: 40px;
      display: inline-block;
      width: 100%;
    }
  }
  &-details{
    flex: 1;
    padding: 0 10px;
    font-size: 14px;
    @media (min-width: 769px) {
      display: flex;
      justify-content: space-between;
    }
    &-item{
      flex: 1;
    }
  }
}
