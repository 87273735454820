@import "~styles/colors";

.add-tags-and-layers-dropdown {
  position: absolute;
  width: 100%;
  z-index: 1000;
  padding-bottom: 9px;
  background: gainsboro;
  border-radius: 10px;
  padding-bottom: 0;

  .select-all {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .select-property {
    display: flex;
    justify-content: space-between;
  }
  .wait-note {
    color: lightskyblue;
  }

  .campaign-users-list {
    padding: 6px 10px;
    padding-right: 5px;
  }

  .campaign-user-list-item {
    margin-bottom: 6px;
  }

  .avatar {
    border-radius: 10px;
  }
}
