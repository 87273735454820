@import "~styles/colors";

.prospect {
  margin: -20px -20px 0 -20px;
  @media (max-width: 768px) {
    margin: 0;
  }
  .dashboard-content {
    padding-top: 0;
    height: calc(100vh - 69px);
    .content {
      padding: 0;
    }
    .sidebar {
      margin-top: 20px;
    }
  }
  &-message-container {
    position: absolute;
    bottom: 10px;
    width: 100%;
    left: 0;
    display: flex;
    align-content: center;
    flex-direction: column;
  }
  &-warning {
    background-color: #e74c3c;
    border-color: #00000000;
    color: #000000;
    align-items: center;
    text-align: center;
    padding: 5px 15px;
    white-space: pre-line;
    min-height: 50px;
    margin-bottom: 15px;
    border-radius: 5px;
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
    display: flex;
    align-self: center;
  }
  &-no-permission {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-marker {
    position: relative;
    span {
      position: absolute;
      left: 17px;
      top: 5px;
      font-weight: 700;
    }
    .contact-info {
      position: absolute;
      left: 19px;
      top: 4px;
      font-weight: 700;
      display: flex;
      flex-direction: column;

      .icon {
        color: #f99c1c;
      }

      .grid-container {
        display: grid;
        grid-template-columns: auto auto;
        padding: 8px;
      }
      .grid-item {
        padding: 2px;
        font-size: 9px;
        text-align: center;
        border-bottom: 1px solid #00aeef;
        color: #00aeef;
      }

      .grid-item:nth-child(odd) {
        border-right: 1px solid #00aeef;
      }

      .grid-item:last-child,
      .grid-item:nth-last-child(2):nth-child(odd) {
        border-bottom: #ffffff;
        height: 13px;
        width: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    .prospect-popup{
      border-radius: 0;
      &-body{
        padding: 15px;
        max-height: calc(100vh - 50px);
        overflow: scroll;
        .form-group{
          display: block;
          margin-bottom: 0;
          .form-group-item{
            padding-bottom: 10px;
            padding-top: 0;
          }
        }
      }
    }
  }
  .react-datepicker-popper {
    z-index: 2;
  }
}
.campaign-filter {
  display: flex;
  align-items: center;
  i {
    margin-right: 15px;
  }
  &-select {
    width: 200px;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.react-tiny-popover-container {
  overflow: initial !important  ;
}

.loading-container {
  background-color: #ffffff;
  position: fixed;
  margin: 0;
  left: calc(50% - 51px);
  top: calc(50% - 51px);
  z-index: 200;
}

.map-loader {
  &-container {
    display: block;
    width: 100%;
    height: 8px;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.34);
    position: fixed;
    z-index: 99;
    left: 0;
    bottom: 0;
  }
  width: 50%;
  height: 100%;
  margin-left: 0;
  background-color: #f99d1c;

  animation-name: map-loader;
  animation-duration: 750ms;
  animation-timing-function: ease-in-out;
  animation-play-state: running;
  animation-iteration-count: infinite;
}

@keyframes map-loader {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
    margin-left: 50%;
  }
  100% {
    width: 0;
    margin-left: 100%;
  }
}
.gm-style-iw .gm-style-iw-chr {display: none;}