@import "~styles/colors";

.maps {
  &-header {
    margin-bottom: 25px;
  }
  &-heading {
    font-size: 25px;
    margin-bottom: 25px;
  }
  &-action {
    @media (min-width: 769px) {
      text-align: right;
    }
    margin-bottom: 10px;
    button {
      &:last-child{
        margin-left: 10px;
      }
    }
  }
  .map-action {
    display: flex;
    justify-content: flex-end;
    position: relative;
    .checkbox-input {
      .checkbox-container {
        margin: 0 0 0 10px;
      }
    }
  }
  .select-map {
    width: 40px;
    i {
      font-size: 24px;
      color: #ed8815;
    }
  }
  .prospect-popup-header {
    button {
      padding: 5px;
    }
  }
  &-table {
    border-spacing: 0;
    border: none;
    width: 100%;
    text-align: left;
    font-size: 15px;
    @media (max-width: 768px) {
      display: none;
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      .list-item-action {
        opacity: 0;
        color: #00aeef;
        background-color: #d2f7ff;
        padding: 8px 10px;
        border-radius: 5px;
        margin-bottom: 5px;
        &:hover {
          color: #00aeef;
          background-color: #a6efff;
        }
      }
      &:hover {
        td {
          background-color: #F3F3F3;
        }
        .list-item-action {
          opacity: 1;
        }
      }
    }
    th {
      color: $color2;
      font-weight: 500;
      margin: 0;
      padding: 12px;
      font-size: 17px;
      button {
        width: 100%;
        text-align: left;
        outline: none;
        border: none;
        align-items: center;
        margin: 0;
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
        background: none;
        i {
          margin-left: 25px;
        }
      }
      .unsorted {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        i {
          line-height: 1;
        }
      }
    }
    td {
      margin: 0;
      padding: 12px;
      font-size: 14px;
      border-top: 0.2px solid #eaeaea;
      button {
        border: none;
        background: none;
        outline: none;
        color: inherit;
        font-family: inherit;
        padding: 0;
      }

      .status {
        &-success {
          color: #73e5b0;
        }
        &-danger {
          color: #ff6265;
        }
      }
      a {
        text-decoration: none;
        color: initial;
      }
    }
  }
  .map-search {
    box-shadow: 0 0 5px #eaeaea;
    border-radius: 5px;
    position: relative;
    &-input {
      background-color: #ececec;
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      input {
        background: none;
        box-shadow: none;
        border: none;
        width: 100%;
        outline: none;
      }
    }
    &-results-list {
      background: #ffffff;
      position: absolute;
      width: 100%;
      z-index: 100;
      box-shadow: 0 0 5px #ececec;
      border-radius: 5px;
      &-item {
        padding: 10px 15px;
        font-size: 14px;
        width: 100%;
        background: none;
        border: none;
        outline: none;
        text-align: left;
        color: #666666;
      }
    }
  }
  .create-new-map-modal{
    .modal-content {
      width: 100%;
      max-width: 600px;
      height: calc(100vh - 100px);
      max-height: 600px;
      &-body{
        height: calc(100% - 50px);
      }
    }
  }
}