.input-range {
  margin-bottom: 15px;
  &-inner {
    display: flex;
    label {
      font-size: 11px;
      display: block;
    }
  }
  &-left {
    flex: 1 1;
    padding-right: 20px;
    .css-yvszuv-Slider {
      width: 100%;
    }
    label {
      margin-bottom: 7px;
    }
  }
  &-right {
    .form-input {
      max-width: 60px;
    }
    &-inner{
      display: flex;
      align-items: center;
      i{
        margin-left: 10px;
        color: #cc6363;
      }
    }
  }
  .form-errors {
    list-style: none;
    font-size: 12px;
    padding: 10px 0 0;
    color: #cc6363;
    text-align: right;
  }
}
