@import "~styles/colors";

.prospect-filter-container {
  background: #fff;
  right: 100px;
  border-radius: 10px;
  font-size: 12px;
  &-header {
    background-color: $color2;
    padding: 10px 15px;
    color: #ffffff;
    font-size: 13px;
    min-width: 300px;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    i {
      color: #000000;
      margin-right: 15px;
      background-color: #ffffff;
      border-radius: 50%;
      font-size: 12px;
      width: 24px;
      display: inline-block;
      text-align: center;
      height: 24px;
      line-height: 24px;
    }
    .close-button {
      margin-left: auto;
      i {
        font-size: 9px;
        line-height: 22px;
        height: 22px;
        width: 22px;
        margin: 0;
      }
    }
  }
  &-body {
    padding: 20px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0 -20px 0;
        padding: 10px 20px;
        border-bottom: 1px solid #e2e2e2;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border: none;
          padding-bottom: 0;
        }
      }
    }
  }
  .item-icon {
    margin-right: 15px;
    font-size: 18px;
  }
}

.action-bar {
  list-style: none;
  position: fixed;
  right: 10px;
  bottom: 10px;
  &-item {
    position: relative;
    button {
      outline: none;
      border: none;
      font-size: 20px;
      border-radius: 50%;
      text-align: center;
      box-shadow: 0 5px 5px #00000016;
      width: 50px;
      line-height: 50px;
      padding: 0;
      height: 50px;
      &.default {
        background-color: #666666;
        color: #ffffff;
        &:disabled {
          background-color: lighten(#666666, 10%);
          cursor: not-allowed;
        }
      }
      &.primary {
        background-color: #f99d1c;
        color: #ffffff;
        &:disabled {
          background-color: lighten(#f99d1c, 10%);
          cursor: not-allowed;
        }
        &.active{
          background-color: darken(#f99d1c, 10%);
          box-shadow: 0 5px 5px #f99d1c60;
        }
      }
      &.info {
        background-color: #71d0f6;
        color: #ffffff;
        &.active {
          background-color: #24a7da;
          box-shadow: 0 5px 5px #24a7da70;
        }
        &:disabled {
          background-color: lighten(#71d0f6, 10%);
          cursor: not-allowed;
        }
      }
      &.secondary {
        background-color: #38C6F4;
        color: #ffffff;
      }
      &.view-mode{
        background-color: #70D1F6;
        color: #ffffff;
      }
      &.manager-view{
        background-color: #D6D6D6;
        &.active{
          background-color: #0AC900;
        }
      }
      &.light {
        background-color: #ffffff;
        color: #7e7e7e;
      }
    }
    &:not(:last-child) {
      height: 50px;
      margin-bottom: 5px;
    }
  }
  @media (min-width: 769px) {
    display: flex;
    align-items: center;
    height: calc(100vh - 100px);
    padding: 20px 5px 20px 15px;
    background-color: #ffffff;
    border-radius: 10px 0 0 10px;
    justify-content: space-between;
    flex-direction: column;
    right: 0;
    bottom: 0;
    &-item {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  .tool-tip-text {
    visibility: hidden;
    text-align: left;
    border-radius: 6px;
    padding: 1px 5px;
    color: #ffffff;
    position: absolute;
    right: 75px;
    z-index: 1000;
    background-color: #666666;
    box-shadow: 0 5px 5px #00000016;
    font-size: 11px;
    top: 20px;
    width: max-content;
  }

  .action-bar-item:hover .tool-tip-text {
    visibility: visible;
  }
}

.filter-list {
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.prospect-view-mode {
  padding: 0;
  &-item {
    display: block;
    background: none;
    outline: none;
    border: none;
    margin: 0 -20px 0;
    padding: 10px 20px;
    width: calc(100% + 40px);
    text-align: left;
    border-bottom: 1px solid #e2e2e2;
    &:last-child {
      padding-bottom: 0;
      border: none;
    }
    &:first-child {
      padding-top: 0;
    }
  }
}
