.campaign-layers {
  .add-layer-modal {
    .modal-content {
      max-width: 600px;
      &-body {
        padding: 35px;
      }
    }
  }
}
