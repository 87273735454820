* {
  box-sizing: border-box;
}
body {
  color: #666666;
  font-family: "Poppins", sans-serif;
  overflow: auto;
}

textarea {
  font-family: inherit;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ScrollbarsCustom-Wrapper {
  right: 10px !important;
}

hr {
  border: 0.5px solid #b1b1b1 !important;
  margin: 10px 0 20px;
}

.create-button {
  font-weight: 500;
  color: #000000;
  display: block;
  text-align: left;
  i {
    background-color: #02aeef;
    padding: 10px;
    border-radius: 50%;
    display: inline-block;
    color: #fff;
    line-height: 1;
    margin-right: 15px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: inherit;
  margin: 0;
  padding: 0;
  &:disabled{
    cursor: not-allowed;
  }
}

.form-group {
  display: flex;
  margin: 0 -10px 20px;
  &-item {
    flex: 1;
    padding: 0 10px;
    label {
      display: block;
      margin-bottom: 5px;
    }
  }
}
img {
  max-width: 100%;
}