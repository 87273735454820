@import "~styles/colors";

.timeline-tab {
  padding: 0 5px;
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 5px;
    .btn.btn-light {
      color: $color1;
    }
  }
  &-content {
    .timeline-item {
      font-size: 10px;
    }
  }
  .modal-content {
    background: #ffffff;
    border-radius: 5px;
    margin: 0 150px;
    box-shadow: 0 0 10px #00000016;
    z-index: 200;
    height: auto;
    max-height: 800px;
    overflow-y: auto;
    width: inherit;
  }
}

.timeline {
  list-style: none;
  padding: 0 10px 0 0;
  margin: 0;
  &-header {
    margin-bottom: 20px;
  }
  &-item {
    position: relative;
    padding-bottom: 20px;
    font-size: 14px;
    &-tail {
      position: absolute;
      left: 13px;
      height: calc(100% - -5px);
      border-left: 2px dashed #e8e8e8;
      &.today {
        height: 37px;
        top: 15px;
      }
      &.past {
        &:not(.add-tail) {
          height: 20px;
        }
      }
    }
    &-head {
      position: absolute;
      background-color: #fff;
      border: 2px solid transparent;
      border-radius: 5px;
      font-size: 13px;
      background: #f99c1c;
      color: #fff;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 26px;
    }
    &-content {
      position: relative;
      margin: 0 0 0 50px;
      &-heading {
        display: flex;
        align-items: center;
        align-content: center;
        & > * {
          flex-basis: 0;
          flex-grow: 1;
        }
        .heading {
          position: relative;
          text-align: center;
          &::before {
            content: "";
            display: block;
            height: 1px;
            width: 100%;
            background: #707070;
            top: calc(50% - 1px);
            position: absolute;
            left: 0;
          }
          p {
            text-align: center;
            letter-spacing: 4px;
            font-size: 16px;
            position: relative;
            margin: 0;
            background-color: #f6f6f6;
            display: inline-block;
            padding: 0 25px;
          }
        }
      }
    }
  }
  &-card {
    display: block;
    text-decoration: none;
    font-size: 13px;
    padding: 10px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #70707014;
    box-shadow: 0 0 10px #00000016;
    margin-bottom: 10px;
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        display: block;
        margin-bottom: 10px;
      }
      &-intro {
        display: flex;
        align-items: center;
      }
      &-action {
        background: none;
        border: none;
        color: $color2;
        margin-left: auto;
        opacity: 0;
        > button {
          padding: 0 5px;
          color: $color2;
        }
        @media (max-width: 768px) {
          opacity: 1;
        }
      }
      &-date,
      &-user {
        padding-left: 10px;
        border-left: 1px solid #00000016;
        margin-left: 10px;
        @media (max-width: 768px) {
          padding-left: 0;
          margin-left: 0;
          border-left: none;
        }
      }
    }
    &-body {
      font-size: 15px;
      @media (max-width: 768px) {
        .radio-button-group{
          flex-direction: column;
          margin: 0;
          .radio-button{
            margin-bottom: 5px;
          }
        }
      }
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      @media (max-width: 768px) {
        margin-top: 10px;
      }
      .date {
        color: #7a7a7a;
        background-color: #e6e7e8;
        &.active {
          background-color: #d8f6d8;
          color: #2dca73;
        }
        padding: 10px 15px;
        border-radius: 5px;
      }
    }
    &:hover {
      .timeline-card-header-action {
        opacity: 1;
      }
    }
  }
  &-error {
    color: #7a7a7a;
    background-color: #e6e7e8;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
  }
}
