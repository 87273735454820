.add-layer {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-content{
    flex: 1;
    max-height: calc(100vh - 120px);
    overflow-x: scroll;
    padding: 0 20px 0 10px;
  }
  .layer-type-group {
    margin-bottom: 20px;
    .form-field {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      i {
        margin-right: 10px;
        font-size: 20px;
      }

      .form-block {
        flex: 1;
      }
    }
  }
  .color-form-group {
    margin-bottom: 25px;
    & > label {
      font-size: 15px;
      margin-bottom: 10px;
      display: block;
    }
    &-inner {
    }
    .add-fade-block {
      padding: 10px 0 20px;
    }
  }
  .button-block {
    text-align: right;
    margin-top: 20px;
    button{
      margin-left: 15px;
    }
  }
}
