.map {
  &-header {
    border-bottom: 4px solid #00aeef;
    border-radius: 10px 10px 5px 5px;
    font-size: 12px;
    @media (min-width: 769px) {
      display: flex;
    }
    &-left {
      flex: 1;
      padding-right: 20px;

      &__description {
        textarea {
          resize: none;
        }
      }
    }
    &-right {
      @media (min-width: 769px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    &-name {
      font-size: 20px;
      margin-bottom: 5px;
    }
    &-description {
      font-size: 15px;
      margin-bottom: 5px;
    }
  }
  .react-datepicker-popper{
    z-index: 2;
  }
}
