@import "~styles/colors";

.multi-select-options-container {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 10;
  .multi-select-options {
    align-self: center;
    background-color: #ffffff;
    cursor: move;
    border-radius: 15px 15px 0 0;
    &-header {
      background-color: $color2;
      padding: 15px;
      border-radius: 15px 15px 0 0;
      color: #ffffff;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        font-size: 14px;
      }
      & > i {
        background-color: #ffffff;
        color: #000000;
        padding: 5px;
        border-radius: 50%;
        margin-right: 15px;
      }
      .close-button {
        background: #fff;
        border-radius: 50%;
        padding: 5px;
        font-size: 8px;
        margin-left: auto;
      }
    }
    &-body {
      padding: 15px;
      @media (min-width: 769px) {
        display: flex;
        align-items: center;
        padding: 20px 35px;
      }
      &-toggle {
        display: flex;
        align-items: center;
        margin-right: 20px;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      button {
        &:not(:last-child) {
          margin-right: 15px;
        }
        @media (max-width: 768px) {
          width: 100%;
          margin-top: 10px;
          margin-right: 0;
          font-size: 12px;
          padding: 7px 10px;
        }
      }
    }
  }
}
