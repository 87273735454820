@import "~styles/colors";
.view-property-details {
  padding: 0 10px;
  &-feature-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item {
      i {
        margin-right: 15px;
      }
    }
    button {
      color: $color2;
      background: none;
      border: none;
    }
  }
  &-list {
    margin: 0 -20px;
    padding: 10px;
    &-item {
      box-shadow: 0 0 5px #00000016;
      padding: 10px;
      border-radius: 5px;
      margin: 10px 10px 20px;
      @media (min-width: 769px) {
        display: flex;
      }
      &-section {
        flex: 1;
        .property-meta {
          font-size: 12px;
          span {
            color: #000000;
            display: block;
          }
        }
        table {
          width: 100%;
          font-size: 12px;
          border-collapse: collapse;
          tr {
            vertical-align: top;
            td {
              padding-right: 10px;
              padding-bottom: 15px;
            }
          }
        }
        .tags-section {
          display: flex;
          &-list {
            flex: 1;
            &-header {
              color: #00aeef;
              font-size: 12px;
              font-weight: 600;
            }
            &-body {
              margin-top: 10px;
            }
          }
        }
      }
      &-name {
        background-color: #c2d5e5;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        button {
          color: $color2;
          background: none;
          border: none;
        }
        span {
          color: #000000;
          display: block;
        }
      }
    }
  }
}
