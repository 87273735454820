.edit-property-details {
  &-item {
    padding: 0 10px;
    margin-bottom: 20px;
    label {
      font-size: 12px;
      display: block;
      margin-bottom: 10px;
    }

    .form-block .form-select .dropdown-container {
      position: relative;
      display: inline-block;
      margin-bottom: 15px;
      top: 0px;
    }
  }
}
