@import "styles/colors";

.campaign-template-list-item {
  margin-bottom: 20px;
  &-container {
    padding-bottom: 15px;
    border-bottom: 1px solid #00aeef;
    @media (min-width: 769px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-header {
    font-size: 15px;
    font-weight: 700;
    margin-right: 20px;
    flex: 2;
  }
  &-description {
    margin-right: 20px;
    flex: 3;
  }
  &-meta {
    margin-right: 20px;
    flex: 2;
    span {
      display: block;
      color: #828282;
      &.user {
        color: #00aeef;
      }
    }
  }
  &-actions {
    @media (min-width: 769px) {
      flex: 1;
      text-align: right;
    }
  }
}
