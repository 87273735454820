$color1: #f99c1c;
$color2: #00aeef;
$color-green-light: #2dca73;
$color-green: #00d202;
$color-red: #ff1700;
$white: #fff;
$stone-gray: #666666;
$light-gray: #f0f0f0;
$dark-gray: #afacac;

$color-accent: #00aeef;
$color-dark-accent: #005474;