.create-campaign-template {
  .access-level {
    display: flex;
    margin: 20px 0 0;
    label {
      margin-right: 15px;
    }
    .switch-container {
      margin-right: 15px;
    }
  }
  &-header {
    display: flex;
    &-on-edit {
      width: 100%;
    }
  }
  .button-block {
    text-align: right;
    margin-bottom: 25px;
  }
}
