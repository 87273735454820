@import "~styles/colors";

.campaign-layer-list-item {
  margin-bottom: 15px;
  &-body {
    .progress-bar {
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      margin-bottom: 20px;
      box-shadow: 0 0 5px #00000026;
      .primary-color {
        height: 15px;
        display: inline-block;
        width: 100%;
      }
    }
    &-meta {
      display: flex;
      &-item {
        flex: 1;
      }
      .button-block {
        opacity: 0;
        button {
          background: none;
          outline: none;
          color: $color2;
          border: none;
        }
        .btn-delete {
          color: red;
        }
      }
    }
  }
  &:hover {
    .campaign-layer-list-item-body {
      &-meta {
        .button-block {
          opacity: 1;
        }
      }
    }
  }
}
