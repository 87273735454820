@import "~styles/colors";

.add-layer-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-content {
    display: flex;
    align-items: center;
    span {
      display: block;
      color: $color1;
    }
    .color-block {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
  &-actions {
    display: flex;
    i {
      color: #c4c4c4;
    }
  }
}
