.property-and-people-search {
  display: flex;
  background: #ececec;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  align-items: center;
  &-input{
    position: relative;
    width: 100%;
    input {
      background: none;
      border: none;
      outline: none;
      padding: 5px 5px 5px 10px;
      width: calc(100% - 24px);
    }
    button{
      position: absolute;
      right: 4px;
      font-size: 12px;
      top: calc(50% - 10px);
      padding: 4px;
    }
  }
  .search-results-list {
    background: #ffffff;
    position: absolute;
    width: 100%;
    z-index: 100;
    box-shadow: 0 0 5px #ececec;
    border-radius: 5px;
    top: 40px;
    left: 0;
    &-item {
      padding: 10px 15px;
      font-size: 14px;
      width: 100%;
      background: none;
      border: none;
      outline: none;
      text-align: left;
      color: #666666;
    }
  }
  .action-background{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    right: 0;
    z-index: -1;
  }
}
